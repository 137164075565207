import React, { Component } from "react"
import QuotesData from "../data/quotes.json"
import Slider from "react-slick"
import SEO from "../components/seo"
import South from "../svgs/South.svg"
import BCL from "../svgs/BCL.svg"
import CarFinance247 from "../svgs/CarFinance247.svg"
import Computype from "../svgs/computype.svg"
import CourseCheck from "../svgs/CourseCheck-01.svg"
import Dock10 from "../svgs/Dock10.svg"
import HomeSafe from "../svgs/HomeSafe.svg"
import IdealHeating from "../svgs/IdealHeating.svg"
import KCCom from "../svgs/KCCom.svg"
import NHS from "../svgs/NHS.svg"
import SingleTrack from "../svgs/SIngleTrack.svg"
import Spencer from "../svgs/Spencer-02.svg"
import Quote from "../components/quote"

class Clients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      quotes: QuotesData,
    }
  }

  render() {
    const slickSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    const pageContent = (
      <>
        <h2>Clients &amp; brands we’ve helped along the way.</h2>
        <ul className="c-client-list">
          <li>
            <IdealHeating></IdealHeating>
          </li>
          <li>
            <CarFinance247></CarFinance247>
          </li>
          <li>
            <NHS></NHS>
          </li>
          <li>
            <HomeSafe></HomeSafe>
          </li>
          <li>
            <CourseCheck></CourseCheck>
          </li>
          <li>
            <SingleTrack></SingleTrack>
          </li>
          <li>
            <Computype></Computype>
          </li>
          <li>
            <BCL></BCL>
          </li>
          <li>
            <Spencer></Spencer>
          </li>
          <li>
            <KCCom></KCCom>
          </li>
          <li>
            <Dock10></Dock10>
          </li>
          <li>
            <South></South>
          </li>
        </ul>
        <Slider {...slickSettings} className="c-quote-slider">
          {this.state.quotes.map(item => (
            <Quote
              key={item.id}
              id={item.id}
              text={item.text}
              person={item.person}
              role={item.role}
            ></Quote>
          ))}
        </Slider>
      </>
    )

    return (
      <main className="o-section">
        <SEO
          title="Clients"
          description="Clients &amp; brands we’ve helped along the way"
          pageClass="s-clients"
        ></SEO>
        <div className="u-grid u-centered">{pageContent}</div>
      </main>
    )
  }
}

export default Clients