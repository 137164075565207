import React from 'react';

const Quote = (props) => {

    Quote.props = {
        text: props.text.isRequired,
        person: props.person.isRequired,
        role: props.role,
      }

    return (
        <div className="c-quote">
            <blockquote className="c-quote__text"><p>{props.text}</p></blockquote>
            <div className="c-quote__attr">
            <p className="c-quote__attr-person"><span>{props.person}</span></p>
            {props.role ? (
                <p className="c-quote__attr-role">{props.role}</p>
            ) : ("")}
            </div>
        </div>
    );
        
    
}

export default Quote